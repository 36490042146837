import { ThemeProvider } from "@fluentui/react";
import { reactErrorHandler } from "@sentry/react";
import { init as initSentry, reactRouterV6BrowserTracingIntegration } from "@sentry/react";
import { StrictMode, useEffect } from "react";
import { createRoot } from "react-dom/client";
import {
	BrowserRouter,
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from "react-router";

import PromptToInstallProvider from "@/component/PromptToInstallProvider";

import App from "./App";

import "./index.scss";

initSentry({
	dsn: import.meta.env.VITE_SENTRY_DSN,
	integrations: [
		reactRouterV6BrowserTracingIntegration({
			useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes,
		}),
		// Disabled feedback integration because I'm running the "errors-only" version of Sentry
		// This saves quite a lot of resources
		// feedbackIntegration({
		// 	showName: false,
		// 	showEmail: false,
		// 	isNameRequired: false,
		// 	isEmailRequired: false,
		// 	buttonLabel: "Feedback geben",
		// 	formTitle: "Feedback",
		// 	messageLabel: "Beschreibung",
		// 	submitButtonLabel: "Senden",
		// 	cancelButtonLabel: "Abbrechen",
		// 	messagePlaceholder: "Was hast du gemacht? Was hast du erwartet? Was ist passiert?",
		// 	successMessageText: "Vielen Dank für dein Feedback!",
		// 	isRequiredLabel: "",
		// 	// https://github.com/getsentry/sentry-javascript/issues/11152
		// 	// errorMessageText: "Feedback konnte nicht gesendet werden. Das kann an einem Werbeblocker liegen.",
		// }),
	],
	environment: import.meta.env.MODE,
	tracePropagationTargets: ["localhost", /^https:\/\/api\.brief\.jetzt/],
	tracesSampleRate: 1.0,
	replaysSessionSampleRate: 0,
	replaysOnErrorSampleRate: 0,
});

// TODO: Use
// import { usePromptToInstall } from "./PromptToInstallProvider";
// To control install prompt

/*
const darkTheme = createTheme({
	palette: {
		themePrimary: "#0e639c",
		themeTertiary: "#083b5d",
		themeSecondary: "#0c5789",

		themeLight: "#252526",
		themeLighter: "#333333",
		themeLighterAlt: "#010406",

		themeDark: "#3782b3",
		themeDarkAlt: "#1e6fa5",
		themeDarker: "#629fc7",

		neutralDark: "#f4f4f4",

		neutralLight: "#3f3f3f",
		neutralLighter: "#313131",
		neutralLighterAlt: "#282828",

		neutralPrimary: "#ffffff",
		neutralPrimaryAlt: "#dadada",

		neutralSecondary: "#d0d0d0",

		neutralQuaternary: "#4f4f4f",
		neutralQuaternaryAlt: "#484848",

		neutralTertiary: "#c8c8c8",
		neutralTertiaryAlt: "#6d6d6d",

		black: "#f8f8f8",
		white: "#1e1e1e",
	},
	isInverted: true,

	palette: {
		neutralLighterAlt: "#282828",
		neutralLighter: "#313131",
		neutralLight: "#3f3f3f",
		neutralQuaternaryAlt: "#484848",
		neutralQuaternary: "#4f4f4f",
		neutralTertiaryAlt: "#6d6d6d",
		neutralTertiary: "#c8c8c8",
		neutralSecondary: "#d0d0d0",
		neutralPrimaryAlt: "#dadada",
		neutralPrimary: "#ffffff",
		neutralDark: "#f4f4f4",
		black: "#f8f8f8",
		white: "#1f1f1f",
		themePrimary: "#3a96dd",
		themeLighterAlt: "#020609",
		themeLighter: "#091823",
		themeLight: "#112d43",
		themeTertiary: "#235a85",
		themeSecondary: "#3385c3",
		themeDarkAlt: "#4ba0e1",
		themeDark: "#65aee6",
		themeDarker: "#8ac2ec",
		accent: "#3a96dd",
	},
});
*/

// biome-ignore lint/style/noNonNullAssertion: It's there
const root = createRoot(document.getElementById("root")!, {
	onUncaughtError: reactErrorHandler((error, errorInfo) => {
		import.meta.env.DEV && console.warn("Uncaught error", error, errorInfo.componentStack);
	}),
	onCaughtError: reactErrorHandler(),
	onRecoverableError: reactErrorHandler(),
});

root.render(
	<StrictMode>
		<PromptToInstallProvider>
			<ThemeProvider>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</ThemeProvider>
		</PromptToInstallProvider>
	</StrictMode>,
);
