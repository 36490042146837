import { Spinner as FluentSpinner, Label } from "@fluentui/react";

export default function Spinner() {
	return (
		<div>
			<Label>Lade...</Label>
			<FluentSpinner label="Lade..." />
		</div>
	);
}
