import { Suspense, lazy } from "react";

import Spinner from "@/component/Spinner";

const LetterEditorLazy = lazy(() => import("@/component/LetterEditor"));

const wasmSupport = (() => {
	// copied from https://stackoverflow.com/questions/47879864/how-can-i-check-if-a-browser-supports-webassembly
	try {
		if (typeof WebAssembly === "object" && typeof WebAssembly.instantiate === "function") {
			const module = new WebAssembly.Module(
				Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00),
			);
			if (module instanceof WebAssembly.Module)
				return new WebAssembly.Instance(module) instanceof WebAssembly.Instance;
		}
	} catch (e) {}
	return false;
})();

export default function Main() {
	if (!wasmSupport) {
		return (
			<div>
				<p>
					Lieber Besucher,
					<br />
					mit brief.jetzt kannst du mit Leichtigkeit einen DIN-gerechten Brief verfassen,
					ohne dich um lästige Formatierung kümmern zu müssen.
					<br />
					Um deine Privatsphäre zu schützen, wird der Inhalt des Briefes dabei{" "}
					<strong>nicht auf unseren Servern verarbeitet</strong>. Damit das funktioniert,
					musst du einen modernen Browser verwenden, der den Brief{" "}
					<strong>direkt auf deinem Endgerät</strong> formatieren und ein PDF erstellen
					kann.
				</p>
				<p>
					<strong>Leider unterstützt dein Browser kein WebAssembly</strong>, weshalb du
					brief.jetzt nicht verwenden kannst. Bitte{" "}
					<strong>aktualisiere deinen Browser</strong> oder verwende einen anderen, um
					brief.jetzt zu nutzen.
				</p>
			</div>
		);
	}
	return (
		<Suspense fallback={<Spinner />}>
			<LetterEditorLazy />
		</Suspense>
	);
}
